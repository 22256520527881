import { gql } from '@apollo/client';

export const ATTRIBUTES = gql`
  query attributes {
    attributes {
      id
      name
      label
      values
      configs
    }
  }
`;

export default ATTRIBUTES;
