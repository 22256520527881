import { gql } from '@apollo/client';

export const OBJECT = gql`
  query object($id: ID!) {
    object(id: $id) {
      id
      name
      type
      description
      attributes
      attachments {
        id
        label
        contentUrl
      }
      defaultAttachment {
        id
        label
        contentUrl
      }
      geolocations {
        id
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;

export const PAGINATED_OBJECTS = gql`
  query paginatedObjects($input: PaginatedObjectsInput!) {
    paginatedObjects(input: $input) {
      objects {
        id
        name
        type
        description
        defaultAttachment {
          id
          label
          contentUrl
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;
